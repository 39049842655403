<template>
  <div class="setting">
    <div class="cardbox">
      <div class="title">用户设置</div>
      <div class="lines">
        <div class="subtit">用户信息</div>
        <div class="line_item">
          <span class="label">名称</span>
          <el-input v-model="setlist.user_name" disabled></el-input>
          <!-- <el-button type="text" @click="sendUserinfo()">修改姓名</el-button> -->
        </div>
        <!-- <div class="subtit">账号信息</div> -->
        <!-- <div class="line_item">
          <span class="label">账号</span>
          <el-input v-model="setlist.email" @blur="OnEmail()" disabled></el-input>
        </div> -->
        <!-- <el-divider></el-divider> -->
        <div class="line_item">
          <span class="label">邮箱</span>
          <el-input v-model="setlist.email" disabled></el-input>
          <!-- <el-button type="text" @click="sendUserinfo()">修改用户名</el-button> -->
        </div>
        <!-- <el-divider></el-divider> -->
        <div class="line_item">
          <span class="label">手机</span>
          <el-input v-model="setlist.phone" disabled></el-input>
          <!-- <el-button type="text" @click="OnPhone()">修改用户信息</el-button> -->
        </div>
        <div class="line_item">
          <span class="label">创建时间</span>
          <el-input v-model="setlist.create_time" disabled></el-input>
          <!-- <el-button type="text" @click="OnPhone()">修改用户信息</el-button> -->
        </div>
        <!-- <el-divider></el-divider>
        <div class="subtit">密码</div>
        <div class="line_item">
          <span class="label">原密码</span>
          <el-input show-password v-model="ruleForm.old_pwd" autocomplete="off" placeholder="请输入原密码"></el-input>
        </div>
        <div class="line_item">
          <span class="label">新密码</span>
          <el-input show-password v-model="ruleForm.new_pwd" autocomplete="off" placeholder="请输入新密码"></el-input>
        </div>
        <div class="line_item">
          <span class="label">确认</span>
          <el-input show-password v-model="ruleForm.confirm_pwd" autocomplete="off" placeholder="请确认新密码"></el-input>
          <el-button type="text" @click="submitForm(ruleForm)">修改密码</el-button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject:['reload'],
  data(){
    // let validatePass = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('必填'));
    //   } else {
    //     // if (this.ruleForm.checkPass !== '') {
    //     //   this.$refs.ruleForm.validateField('checkPass');
    //     // }
    //     callback();
    //   }
    // };
    // let validatePass2 = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请再次输入密码'));
    //   } else if (value !== this.ruleForm.new_pwd) {
    //     callback(new Error('两次输入密码不一致!'));
    //   } else {
    //     callback();
    //   }
    // };
    
    return{
      uid:'',
      mima:false,
      addgs:false,
      setlist:{},
      ruleForm:{
        old_pwd:'',
        new_pwd:'',
        confirm_pwd:''
      },
      // rules: {
      //     old_pwd: [
      //       { validator: validatePass, trigger: 'blur' }
      //     ],
      //     new_pwd: [
      //       { validator: validatePass, trigger: 'blur' }
      //     ],
      //     confirm_pwd: [
      //       { validator: validatePass2, trigger: 'blur' }
      //     ],
      // },
    }
  },
  methods: {
    relogin(){// 重新登录
      localStorage.removeItem('ywInfo');
      this.$router.push('/login');
    },
    submitForm(form){// 修改密码
      if(form.confirm_pwd =='' || form.new_pwd =='' || form.old_pwd ==''){
        this.$message.warning("密码不能为空");
      }else if(form.confirm_pwd===form.new_pwd){
        this.$http({
          method:'post',
          url: '/user/modify_pwd',
          data:form
        }).then(res => {
          if(res.data.code == 200){
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.relogin()
          }else{
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        }).catch(error=>{
          console.log('error')
        })
      }else{
        this.$message.warning("新密码与确认密码不一致");
      }
    },
    getUserinfo(){
      this.$http({
        method:'post',
        url: '/userinfo'
      }).then(res => {
        console.log(res);
        this.setlist = res.data.data
      }).catch(error=>{
        console.log('error')
      })
    }

  },
  created(){
    // this.rights = JSON.parse(localStorage.getItem("userinfo"));
    // this.getsetting()
    this.getUserinfo()
  }
}
</script>

<style lang="scss" scoped>
  .setting ::v-deep{
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 0px 15px rgba(174, 187, 216, 0.5);
    height: 100%;
    padding: 24px;
    font-size: 16px;
    color:$text-dark;
    border-radius: 28px;
    // color: ;
    .cardbox{
      // background-color: #fff;
      // border-radius: 20px;
      padding: 24px;
      .title{
        font-size: 30px;
        margin-bottom: 50px;
        color:$defult;
      }
      .lines{
        .el-button{
          font-size: 16px;
          font-weight: normal;
          margin-right: 12px;
        }
        .el-input{
          width: 300px;
          margin-right: 10px;
          font-size: 16px;
          .el-input__inner{
            border: none;
            background-color:$light-base;
            color: $defult;
            &::placeholder{
              color:$text-gray;
              font-size: 14px;
            }
          }
        }
        .subtit{
          // color:$grey-light;
          margin-bottom: 30px;
        }
        .line_item{
          display: flex;
          margin-bottom: 30px;
          align-items: center;
          .el-avatar{
            margin-right: 14px;
          }
          .tips{
            // color:$grey-darkwhite;
          }
          .label{
            display: inline-block;
            width: 70px;
          }
          .cnt{
            margin-right: 25px;
          }
        }
      }
    }
  }
</style>